export const getDomain = () => {
    switch(process.env.NODE_ENV) {
        case 'development':
            return ''
        case 'qat':
            return ''
        case 'staging':
            return 'https://api.maxgatesoftware.com'
        case 'production':
            return 'https://api.maxgatesoftware.com' 
        default:
            return ''
    }
}