export default {
    /**
     * 數字位數補0
     * @param {string, number}
     * @returns {string}
     */
    padLeft: function(str, len) {
        str = '' + str;
        return str.length >= len ? str : new Array(len - str.length + 1).join("0") + str;
    },
    /**
     * UTC日期刪除小時分鐘
     * @returns {string}
     */
    getUTCtoDayStr: function() {
        var date = new Date();
        var outDate = (date.getUTCFullYear().toString() 
                        + "-" + this.padLeft((date.getUTCMonth() + 1).toString(), 2) 
                        + "-" + this.padLeft(date.getUTCDate().toString(), 2));
                      
        return outDate;
    },
}