<template>
    <transition name="peroidHistory"> 
        <!--history pc -->
        <div v-if="!isMobile">
            <section style="background:rgb(0, 0, 0);">
                <div class="history">
                    <h1>DRAW HISTORY</h1>
                    <div class="time"> <DatePicker v-model="time" valueType="format" format="YYYY-MM-DD" @change="initGetData" :editable="false" :clearable="false"></DatePicker></div>
                    <div class="tableWrapper">
                        <div class="loading" v-if="isLoading">
                            <img src="../assets/imgs/loading.gif">
                        </div>    
                        <table v-if="!isLoading">
                            <thead>
                                <tr>
                                    <th>Period No.</th>
                                    <th style="min-width: 110px;">Date</th>
                                    <th>Results</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-show="!isNoData" v-for = "(item,index) in data" :key="index">
                                    <td>{{item.PeriodNo}}</td>
                                    <td>{{item.OpenTime | formatToUTCTime}}</td>
                                    <td>
                                        <div class="ballWrapper">
                                            <div v-for="(item, index) in item.OpenCodeList" :key="'history' + index" class="ball">
                                                {{item}}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-show="isNoData" style="text-align: center"> 
                                    <td colspan="3"> NO DATA</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <paginate v-if="isShowPaginate"
                        v-show="allData.length>0"
                        :page-count="paginateOption.pageCount"
                        :click-handler="changePage"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                    </paginate>
                </div>
            </section>
        </div>
        <!--history mobile -->
        <div v-if="isMobile">
            <section style="background:rgb(0, 0, 0);">
                <div class="history">
                    <h1>DRAW HISTORY</h1>
                    <div class="time"> <DatePicker v-model="time" valueType="format" format="YYYY-MM-DD" @change="initGetData" :editable="false" :clearable="false"></DatePicker></div>
                    <div class="tableWrapper">
                        <div class="loading" v-if="isLoading">
                            <img src="../assets/imgs/loading.gif">
                        </div>  
                        <table v-if="!isLoading">
                            <tbody>
                                <tr v-show="!isNoData" v-for = "(item,index) in data" :key="index">
                                    <td>
                                        <p>Period No. {{item.PeriodNo}}</p>
                                        <p>Date: {{item.OpenTime | formatToUTCTime}}</p>
                                        <div class="ballWrapper">                                        
                                            <div v-for="(item, index) in item.OpenCodeList" :key="'history' + index" class="ball">
                                                {{item}}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-show="isNoData" style="text-align: center"> 
                                    <td colspan="1"> NO DATA</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>   
                    <paginate v-if="isShowPaginate || !isLoading"
                        v-show="allData.length>0"
                        :page-count="paginateOption.pageCount"
                        :page-range="3"
                        :margin-pages="1"
                        :next-text="'>'"
                        :prev-text="'<'"
                        :click-handler="changePage"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                    </paginate>
                </div>
            </section>
        </div>
    </transition> 
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import lib from '@/lib';
import {getDomain} from '@/config';
export default {
    props: {
        lotteryCode: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            time:lib.getUTCtoDayStr(),
            data:[],
            allData:[],
            isNoData:false,
            isLoading:false,
            paginateOption:{
                pageCount:0, //總頁數
                pageIndex:1, //第幾頁
                pageSize:20, //1頁幾筆
            },
            isShowPaginate: true,
            isMobile: this.checkWindowSizeIsMobile(),
        }
    },
    components: {
        DatePicker
    },
    methods: {
        async initGetData(){
            this.paginateOption.pageIndex = 1;
            this.allData = [];
            var timestamp = Date.now();
            let apiUrl = getDomain();

            //https://api.maxgatesoftware.com/uk/Gamma3Ex/111/allday/2019-04-12
            this.isLoading = true;
            const result = await this.$axios.get(`${apiUrl}/uk/${this.lotteryCode}/${timestamp}/allday/${this.time}`);
            this.isLoading = false;

            // 判斷是否有資料
            if(result.data.Data == null || result.data.Data.Results.length ==0 ){
                this.isNoData = true;
                return false;
            }else{
                this.isNoData = false;
                this.allData = result.data.Data.Results;
                // 總頁數
                this.paginateOption.pageCount = this.allData.length % 20 == 0 ? this.allData.length / 20 : Math.ceil(this.allData.length/20);
                this.queryData();
            }

            this.isShowPaginate = false;
            this.$nextTick(() => {
                this.isShowPaginate = true;
            });
        },
        queryData(){
            // 取20筆
            var start = (this.paginateOption.pageIndex - 1) * this.paginateOption.pageSize;
            var end  = this.paginateOption.pageIndex * this.paginateOption.pageSize;
            //console.log(start,end)

            this.data= this.allData.slice(start,end);
            let _data = this.data;

            //切割獎號
            this.data.forEach((element, index) => {
                let OpenCodeList = element.OpenCode.split(",");
                _data[index].OpenCodeList = OpenCodeList;
            });
            
            this.data = _data;
        },       
        changePage(page){
            this.paginateOption.pageIndex = page;
            this.queryData();
        },
        onResize() {
            this.isMobile = this.checkWindowSizeIsMobile();
        },
        checkWindowSizeIsMobile () {
            if(window.outerWidth <= 414) {
                return true;
            } else {
                return false;
            }
        },
    },
    mounted(){
        this.initGetData();
         this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });   
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
}
</script>
<style lang="scss" scoped>
.history{
    padding: 47px 0 40px 0;
    
    h1{
        font-size: 28px;
        text-align: center;
        color: #299EF9;
        margin-bottom: 32px;
    }
    .time{
        text-align: center;
        margin-bottom: 25px;
    }
    .tableWrapper{
        .loading{
            text-align: center;
            color: #fff;
            margin: 0 auto;
            width: 60px;
        }
        table{
            margin: 0 auto;
            width: 530px;
            border: 1px solid #555555;
            
            @media(max-width: 414px) {
                width: 90%;
            }
            thead{
                color: #fff;
                line-height: 40px;
                border: 1px solid #555555;
                th{
                    border: 1px solid #555555;
                }
            }
            td{ 
                border: 1px solid #555555;
                color: #fff;
                font-size: 16px;
                padding: 5px;
                @media(max-width: 414px) {
                    padding: 10px 10px;
                    font-size: 14px;
                    p{
                        line-height: 20px;
                    }
                }
                .ballWrapper{
                    display: flex;
                    @media(max-width: 414px) {
                        margin-top: 10px;
                        flex-wrap: wrap;
                    }
                    .ball{
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        color:#299EF9 ;
                        border: 1px solid #299EF9; 
                        text-align: center;
                        line-height: 30px;
                        margin-right: 5px;
                    }
                }
            }
            
        }
    }
   
}
</style>